import { graphql } from 'gatsby';
import React from 'react';
import { FluidObject } from 'gatsby-image';

import { lighten, setLightness } from 'polished';
import { css } from '@emotion/core';
import { colors } from '../styles/colors';
import PostContent from '../components/PostContent';
import TopicContent from '../components/TopicContent';

import { Subscribe } from '../components/subscribe/Subscribe';

import styled from '@emotion/styled';
import { Footer } from '../components/Footer';
import SiteNav from '../components/header/SiteNav';
import { PostCard } from '../components/PostCard';
import { Wrapper } from '../components/Wrapper';
import IndexLayout from '../layouts';
import {
  inner,
  outer,
  PostFeed,
  SiteDescription,
  SiteQuote,
  SiteHeader,
  SiteHeaderContent,
  SiteMain,
  SiteTitle,
  SiteNavMain,
  SiteArchiveHeader,
  ResponsiveHeaderBackground,
  SiteHeaderBackground,
} from '../styles/shared';

import { ShareButtons } from "../components/ShareButtons"

import { PageContext } from './post';
import { Helmet } from 'react-helmet';
import config from '../website-config';

interface TagTemplateProps {
  location: Location;
  pageContext: {
    tag: string;
  };
  data: {
    allTagsYaml: {
      edges: Array<{
        node: {
          id: string;
          quote: string;
          description: string;
          //image?: {
            //childImageSharp: {
              //fluid: FluidObject;
            //};
          //};
        };
      }>;
    };
    allMarkdownRemark: {
      totalCount: number;
      edges: Array<{
        node: PageContext;
      }>;
    };
  };
}

const Tags = ({ pageContext, data, location }: TagTemplateProps) => {
  const tag = pageContext.tag ? pageContext.tag : '';
  const { edges, totalCount } = data.allMarkdownRemark;
  const tagData = data.allTagsYaml.edges.find(
    n => n.node.id.toLowerCase() === tag.toLowerCase(),
  );

  let cardImage = `${config.siteUrl}${data.header.childImageSharp.fixed.src}`
  let { width, height } = data.header.childImageSharp.fixed;

  console.log(tagData);
  if(tagData.node && tagData.node.quote) {
    cardImage = config.siteUrl + location.pathname + 'card.jpg';
    width = 600;
    height = 314;
  }

  const title = `${tag} - ${config.title}`
  const shortTitle = `${tag}`;
  const twitterHandle = config.twitter.split('https://twitter.com/')[1];
  const url = location.href;
  const tags = [tag];

  return (
    <IndexLayout className="post-template">
      <Helmet>
        <html lang={config.lang} />
        <title>
          {tag} - {config.title}
        </title>
        <meta name="description" content={tagData?.node ? tagData.node.quote : ''} />
        <meta property="og:site_name" content={config.title} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`${tag} - ${config.title}`} />
        <meta property="og:description" content={tagData?.node ? tagData.node.quote : ''} />
        <meta property="og:url" content={config.siteUrl + location.pathname} />
        {config.facebook && <meta property="article:publisher" content={config.facebook} />}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`${tag} - ${config.title}`} />
        <meta name="twitter:url" content={config.siteUrl + location.pathname} />
        <meta property="twitter:description" content={tagData?.node ? tagData.node.quote : ''} />
        {config.twitter && (
          <meta
            name="twitter:site"
            content={`@${config.twitter.split('https://twitter.com/')[1]}`}
          />
        )}
        <meta name="og:image" content={cardImage} />
        <meta name="twitter:image" content={cardImage} />
        <meta property="og:image:width" content={width.toString()} />
        <meta property="og:image:height" content={height.toString()} />
      </Helmet>
      <Wrapper css={PostTemplate}>

        <header css={[outer, SiteNavMain]} style={{padding: '0 5vw'}} className="site-header" >
          <div css={inner}>
            <SiteNav isHome={false} isPost={true} post={{title: shortTitle}} />
          </div>
        </header>

        <main id="site-main" css={[SiteMain, outer]}>

          <div css={inner}>

            <article css={[PostFull,  NoImage]}>

              <SiteHeaderContent className="post-full-header">
                <SiteTitle className="post-full-title">{tag}</SiteTitle>

                <SiteQuote style={{textAlign:'center'}} className="site-quote">
                  {tagData?.node.quote ? ( tagData.node.quote) : ( <> </>)}
                </SiteQuote>
              </SiteHeaderContent>

                { tagData?.node.description ? (
                  <div style={{ }} >

                    <TopicContent htmlAst={tagData.node.description} />
                </div>
                ) :  ( <> </>)}

                    {config.showSubscribe && <Subscribe title={config.title} />}
                    <br/>

                   <ShareButtons title={title} url={url} twitterHandle={twitterHandle} tags={tags} />
                   <br/>
                   <br/>
              <div css={[PostFeed]}>
                {edges.map(({ node }) => (
                  <PostCard key={node.fields.slug} post={node} />
                ))}
              </div>
            </article>
          </div>
        </main>
        <Footer />
      </Wrapper>
    </IndexLayout>
  );
};

const PostTemplate = css`
  .site-main {
    margin-top: 64px;
    background: #fff;
    padding-bottom: 4vw;
  }


  //@media (prefers-color-scheme: dark) {
    .site-main {
      /* background: var(--darkmode); */
      background: ${colors.darkmode};
    }
  //}
`;

export const PostFull = css`
  position: relative;
  z-index: 50;
`;

export const NoImage = css`
  .post-full-content {
    padding-top: 0;
  }

  .post-full-content:before,
  .post-full-content:after {
    display: none;
  }
`;

export const PostFullHeader = styled.header`
  position: relative;
  margin: 0 auto;
  padding: 70px 170px 50px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;

  @media (max-width: 1170px) {
    padding: 60px 11vw 50px;
  }

  @media (max-width: 800px) {
    padding-right: 5vw;
    padding-left: 5vw;
  }

  @media (max-width: 500px) {
    padding: 20px 0 35px;
  }
`;

const PostFullTags = styled.section`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* color: var(--midgrey); */
  color: ${colors.midgrey};
  font-size: 1.3rem;
  line-height: 1.4em;
  font-weight: 600;
  text-transform: uppercase;
`;

const PostFullCustomExcerpt = styled.p`
  margin: 20px 0 0;
  color: var(--midgrey);
  font-family: Georgia, serif;
  font-size: 2.3rem;
  line-height: 1.4em;
  font-weight: 300;

  @media (max-width: 500px) {
    font-size: 1.9rem;
    line-height: 1.5em;
  }

  //@media (prefers-color-scheme: dark) {
    /* color: color(var(--midgrey) l(+10%)); */
    color: ${lighten('0.1', colors.midgrey)};
  //}
`;

const PostFullByline = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 35px 0 0;
  padding-top: 15px;
  /* border-top: 1px solid color(var(--lightgrey) l(+10%)); */
  //border-top: 1px solid ${lighten('0.1', colors.lightgrey)};

  .post-full-byline-content {
    flex-grow: 1;
    display: flex;
    align-items: flex-start;
  }

  .post-full-byline-content .author-list {
    justify-content: flex-start;
    padding: 0 12px 0 0;
  }

  .post-full-byline-meta {
    margin: 2px 0 0;
    /* color: color(var(--midgrey) l(+10%)); */
    color: ${lighten('0.1', colors.midgrey)};
    font-size: 1.2rem;
    line-height: 1.2em;
    letter-spacing: 0.2px;
    text-transform: uppercase;
  }

  .post-full-byline-meta h4 {
    margin: 0 0 3px;
    font-size: 1.3rem;
    line-height: 1.4em;
    font-weight: 500;
  }

  .post-full-byline-meta h4 a {
    /* color: color(var(--darkgrey) l(+10%)); */
    color: ${lighten('0.1', colors.darkgrey)};
  }

  .post-full-byline-meta h4 a:hover {
    /* color: var(--darkgrey); */
    color: ${colors.darkgrey};
  }

  .post-full-byline-meta .bull {
    display: inline-block;
    margin: 0 4px;
    opacity: 0.6;
  }

  //@media (prefers-color-scheme: dark) {
    /* border-top-color: color(var(--darkmode) l(+15%)); */
    //border-top-color: ${lighten('0.15', colors.darkmode)};

    .post-full-byline-meta h4 a {
      color: rgba(255, 255, 255, 0.75);
    }

    .post-full-byline-meta h4 a:hover {
      color: #fff;
    }
  //}
`;

export const PostFullTitle = styled.h1`
  margin: 0 0 0.2em;
  color: ${setLightness('0.05', colors.darkgrey)};
  @media (max-width: 500px) {
    margin-top: 0.2em;
    font-size: 3.3rem;
  }

  //@media (prefers-color-scheme: dark) {
    color: rgba(255, 255, 255, 0.9);
  //}
`;

const PostFullImage = styled.figure`
  margin: 25px 0 50px;
  height: 400px;
  background: ${colors.black} center center;
  background-size: cover;
  border-radius: 5px;
  object-fit: contain !important;
  text-align: center;

  .gatsby-image-wrapper {
    picture {
      img {
       object-fit: contain !important;
       filter: brightness(75%) !important;
      }
    }
  }

  @media (max-width: 1170px) {
    margin: 25px -6vw 50px;
    border-radius: 0;
    img {
      max-width: 1170px;
    }
  }

  @media (max-width: 800px) {
    height: 400px;
  }
  @media (max-width: 500px) {
    margin-bottom: 4vw;
    height: 350px;
  }
`;


export default Tags;

export const pageQuery = graphql`
  query($tag: String) {
    header: file(relativePath: { eq: "img/emptyrobot-background.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 1200, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    allTagsYaml {
      edges {
        node {
          id
          quote
          description
        }
      }
    }
    allMarkdownRemark(
      limit: 100000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] }, draft: { ne: true } } }
    ) {
      totalCount
      edges {
        node {
          excerpt
          timeToRead
          frontmatter {
            title
            excerpt
            tags
            date
            author
          }
          fields {
            layout
            slug
          }
        }
      }
    }
  }
`;

